/* -----------------------------------------------------------------------------
truLOCAL - JS Core
-------------------------------------------------------------------------------- */
import * as Sentry from "@sentry/browser";

// Import Modules
import Images from './modules/images'
import Cookies from './modules/cookies'
import Chart from './modules/chart'
import Carousel from './modules/carousel'
import Filters from './modules/filters'
import Modal from './modules/modal'
import Panel from './modules/panel'
import Forms from './modules/forms'
import Toast from './modules/toast'
import Multiselect from './modules/multiselect'
import Onboarding from './modules/onboarding'
import GiftCardCheckout from './modules/gift-card-checkout'
import DatePicker from './modules/datepicker'
import Segment from './modules/segment'
import ShippingDate from './modules/shipping-date'
import Validation from './modules/validation'
import PrepackedGrid from './modules/prepacked-grid'
import dateFormat from './modules/date.format'
import Utils from './modules/utils';
import ShippingProgress from "./modules/shipping_progress";


// Globals
window.Cookies = Cookies
window.getCookie = Cookies.getCookie
window.setCookie = Cookies.setCookie
window.Toast = Toast
window.Chart = Chart
window.Modal = Modal
window.GiftCardCheckout = GiftCardCheckout
window.Segment = Segment
window.Validation = Validation
window.PrepackedGrid = PrepackedGrid
window.dateFormat = dateFormat
window.Utils = Utils
window.ShippingProgress = ShippingProgress

// CSS Vars
function initStyles() {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

// Inits
document.addEventListener('DOMContentLoaded', () => {
  // init the Sentry Browser SDK as soon as possible during your page load
  let sentryDSN = $('meta[name=sentry_dsn]').attr("content");
  if(sentryDSN){
    Sentry.init({
      dsn: sentryDSN,
      integrations: [
        new Sentry.BrowserTracing(),
      ],

      tracesSampleRate: 1.0,
    });
  }
  Images.init()
  Chart.init()
  Cookies.init()
  Carousel.Locals.init()
  Carousel.Products.init()
  Carousel.Testimonials.init()
  Carousel.Recipes.init()
  Carousel.Reviews.init()
  Filters.init()
  Modal.init()
  Panel.init()
  Forms.Select.init()
  Multiselect.init()
  Onboarding.init()
  DatePicker.init()
  Segment.init()
  ShippingDate.init()
  Validation.init()
  ShippingProgress.init()
})

// Click
document.addEventListener('click', () => {
  Multiselect.hide()
})

// Rsize
document.addEventListener('resize', () => {
  initStyles()
  Filters.reset()
})
